import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { logout } from './store/clear-state.metareducer';
import { AuthModule } from '@auth/auth.module';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { enableMapSet } from 'immer';
import { environment } from '../environments/environment';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';

registerLocaleData(localeFr, 'fr');
enableMapSet();

// Common Modules to import
const modulesToImport = [
  BrowserModule,
  AuthModule,
  AppRoutingModule,
  BrowserAnimationsModule,
  MatSnackBarModule,
  StoreModule.forRoot({ router: routerReducer }, { metaReducers: [logout] }),
  EffectsModule.forRoot([]),
  StoreRouterConnectingModule.forRoot(),
];

// Add StoreDevtoolsModule to modules when not in production
if (!environment.production) {
  modulesToImport.push(
    StoreDevtoolsModule.instrument({
      maxAge: 40, // Retains last 40 states
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      // trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      // traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      serialize: {
        options: {
          map: true,
        },
      },
      connectInZone: true,
    })
  );
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [...modulesToImport],
  providers: [
    provideCharts(withDefaultRegisterables()),
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
