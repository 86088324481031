import { createReducer, on } from '@ngrx/store';
import { AuthState, initialState } from './auth.state';
import {
  AuthApiActions,
  AuthGuardActions,
  AuthModuleActions,
  LoginPageActions,
  RefreshTokenInterceptorActions,
} from './auth.actions';

export const authReducer = createReducer(
  initialState,
  on(
    AuthModuleActions.setTheInitialToken,
    (state, { token }): AuthState => ({
      ...state,
      token,
    })
  ),
  on(
    LoginPageActions.loginViaCredentials,
    LoginPageActions.loginViaSSO,
    AuthModuleActions.getUserPermissions,
    (state): AuthState => ({
      ...state,
      loading: true,
    })
  ),
  on(
    AuthApiActions.successfullyLoggedIn,
    AuthApiActions.successfullyGotJwtTokenWithOAuthToken,
    (state, { token }): AuthState => ({
      ...state,
      token,
      loading: false,
    })
  ),
  on(
    AuthApiActions.failedToLogIn,
    AuthApiActions.failedToGetJwtTokenWithOAuthToken,
    AuthApiActions.failedToGetUsersPermissions,
    (state): AuthState => ({
      ...state,
      loading: false,
    })
  ),
  on(
    AuthGuardActions.saveTheToken,
    RefreshTokenInterceptorActions.saveTheToken,
    AuthModuleActions.saveTheToken,
    (state, { token }): AuthState => ({
      ...state,
      token,
    })
  ),
  on(
    AuthApiActions.successfullyGotUsersPermissions,
    (state, { permissions }): AuthState => ({
      ...state,
      permissions,
      loading: false,
    })
  )
);
