import { UserPermissions } from '@core/models/user-permissions';

export interface AuthState {
  loading: boolean;
  token?: string;
  permissions?: UserPermissions;
}

export const initialState: AuthState = {
  loading: false,
};
