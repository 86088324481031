import { inject, Injectable } from '@angular/core';

import { catchError, map, Observable, of, tap } from 'rxjs';
import { AuthHelperService } from './auth-helper.service';
import { AuthApiService } from './auth-api.service';
import { switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthGuardActions } from '../store/auth.actions';
import { CanActivate, CanMatch } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanMatch {
  private $authHelper = inject(AuthHelperService);
  private $authApi = inject(AuthApiService);
  private store = inject(Store);

  canActivate() {
    return this.loginCheck();
  }

  canMatch() {
    return this.loginCheck();
  }

  /**
   * Checks if user is authenticated or needs to perform login with Single Sign-On (SSO).
   * If SSO option is not enabled and the user is not authenticated, user will be logged out.
   * If user does not have a refresh token, SSO login will be initiated.
   * If user has a refresh token, it will be exchanged for a new refresh token and a JWT token.
   * The new JWT token will be stored for further use.
   *
   * @return {boolean | Observable<boolean>} Returns `true` if user is authenticated or successfully logged in with SSO,
   * or an Observable that emits `true` after exchanging refresh token for JWT token.
   * Returns `false` if the user is not authenticated and SSO is not enabled or user does not have a refresh token.
   */
  private loginCheck(): boolean | Observable<boolean> {
    if (this.$authHelper.isAuthenticated) {
      return true;
    }
    if (!this.$authHelper.isSSO) {
      this.$authHelper.logout();
      return false;
    }
    if (!this.$authHelper.hasRefreshToken) {
      this.$authHelper.loginSSO();
      return false;
    }
    return this.$authApi.getNewRefreshToken(this.$authHelper.refreshToken).pipe(
      tap((info) => {
        this.$authHelper.setCookieWithRefreshToken(info.refresh_token);
      }),
      switchMap((info) =>
        this.$authApi.getJwtTokenByOAuthToken(info.access_token).pipe(
          tap((response) => {
            this.$authHelper.setCookieWithToken(response.token);
            this.store.dispatch(
              AuthGuardActions.saveTheToken({ token: response.token })
            );
          }),
          map(() => true)
        )
      ),
      catchError(() => {
        this.$authHelper.loginSSO();
        return of(false);
      })
    );
  }
}
